import React from "react";
import Slider from "react-slick";
import img1 from '../images/casino2.jpg'
import img2 from '../images/casino3.jpg'
import img3 from '../images/casino4.jpg'

const Casino = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const casino = [
    {
      name: "Teenpatti one day",
      img1: img1
    },
    {
      name: "Amar Akbar Anthony",
      img1: img2
    },
    {
      name: "PLAY Bollywood Casino",
      img1: img3
    }, {
      name: "Roulette",
      img1: img1
    },
    {
      name: "Sicbo",
      img1: img2
    },

    {
      name: "Teenpatti one day",
      img1: img1
    },
    {
      name: "Amar Akbar Anthony",
      img1: img2
    },
    {
      name: "PLAY Bollywood Casino",
      img1: img3
    }, {
      name: "Roulette",
      img1: img1
    },
    {
      name: "Sicbo",
      img1: img2
    },

    {
      name: "Teenpatti one day",
      img1: img1
    },
    {
      name: "Amar Akbar Anthony",
      img1: img2
    },
    {
      name: "PLAY Bollywood Casino",
      img1: img3
    }, {
      name: "Roulette",
      img1: img1
    },
    {
      name: "Sicbo",
      img1: img2
    },

    {
      name: "Teenpatti one day",
      img1: img1
    },
    {
      name: "Amar Akbar Anthony",
      img1: img2
    },
    {
      name: "PLAY Bollywood Casino",
      img1: img3
    }, {
      name: "Roulette",
      img1: img1
    },
    {
      name: "Sicbo",
      img1: img2
    },

  ]
  return (
    <div className="mt-1 max-h-screen overflow-y-scroll mb-20">
      <div className="slider-container ">
        <Slider {...settings}>
          {casino.slice(0, 10).map((val) => {
            return (
              <div className="rounded-lg">
                <img src={val.img1} className="w-full px-3 rounded-lg" alt="" />
              </div>
            )
          })}

        </Slider>
      </div>

      <div className="mt-3 px-3">
        <p className="text-white text-xl font-bold">Indian Casino</p>

        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3 mt-3">
          {casino.map((val) => {
            return (
              <div>
                <div className="relative cursor-pointer overflow-hidden mb-0 rounded-md inline-block bg-center bg-no-repeat bg-cover transition-all duration-500 ease-in-out">
                  <img src={val.img1} className="rounded-lg h-32" alt="" />
                  <div className="absolute top-0 left-0 opacity-0 z-10  w-full h-full rounded-md flex items-center justify-center bg-black bg-opacity-70 transition-all duration-500 hover:opacity-100">
                    <button className="bg-[#169c59] text-sm px-3.5 py-1 rounded-full">PLAY</button>
                  </div>
                </div>

                <p className="text-center font-bold mt-0">{val.name}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div >
  );
}

export default Casino