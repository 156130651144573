

import React, { useEffect, useRef, useState } from 'react'
import { BsCurrencyBitcoin } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { FaAndroid, FaBitcoinSign, FaEye, FaEyeSlash, FaIdBadge, FaMobile, FaPlus, FaUser, FaWhatsapp } from 'react-icons/fa6';
import { FaUserCircle } from 'react-icons/fa';
import { MdInput, MdLogout, MdOutlineLeaderboard, MdPublishedWithChanges } from 'react-icons/md';
import { TbLogout } from "react-icons/tb";
import { BiChip, BiLineChart } from 'react-icons/bi';
import { HiCash } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { IoLogoAndroid } from "react-icons/io";
import captcha from '../../images/captch.png'
const Header = () => {


    // function to set a given theme/color-scheme
    function setTheme(themeName) {
        localStorage.setItem('theme', themeName);
        document.documentElement.className = themeName;
    }

    // function to toggle between light and dark theme
    function toggleTheme() {
        if (localStorage.getItem('theme') === 'dark') {
            setTheme('theme-light');
        } else {
            setTheme('dark');
        }
    }

    // ------------------R----------------------
    // -----------------Profile--------------------
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // ------------------R----------------------
    // -----------------Profile--------------------
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    const dropdownRef2 = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef2]);

    const toggleDropdown2 = () => {
        setIsDropdownOpen2(!isDropdownOpen2);
    };
    // -----------------Payment--------------------
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const PaymentRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (PaymentRef.current && !PaymentRef.current.contains(event.target)) {
                setIsPaymentOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [PaymentRef]);
    const togglePayment = () => {
        setIsPaymentOpen(!isPaymentOpen);
    };


    const [isPaymentOpen2, setIsPaymentOpen2] = useState(false);
    const PaymentRef2 = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (PaymentRef2.current && !PaymentRef2.current.contains(event.target)) {
                setIsPaymentOpen2(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [PaymentRef2]);

    const togglePayment2 = () => {
        setIsPaymentOpen2(!isPaymentOpen2);
    };

    const [showChipsSettingPopup, setShowChipsSettingPopup] = useState(false);
    const [showchangePasswordPopup, setShowchangePasswordPopup] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState(false);
    const [showDemoPopup, setShowDemoPopup] = useState(false);
    const [showForgotpassPopup, setShowForgotpassPopup] = useState(false);
    const [currentPopup, setCurrentPopup] = useState(null);

    const closeCurrentPopup = () => {
        switch (currentPopup) {
            case "chipsSetting":
                setShowChipsSettingPopup(false);
                break;
            case "changePassword":
                setShowchangePasswordPopup(false);
                break;
            case "login":
                setShowLoginPopup(false);
                break;
            case "signUp":
                setShowSignUpPopup(false);
                break;
            case "demo":
                setShowDemoPopup(false);
                break;
            case "forgotPass":
                setShowForgotpassPopup(false);
                break;
            default:
                break;
        }
    };

    const handleUpdateChipsSettingClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "chipsSetting") {
            closeCurrentPopup();
            setShowChipsSettingPopup(true);
            setCurrentPopup("chipsSetting");
        } else {
            setShowChipsSettingPopup(false);
            setCurrentPopup(null);
        }
    };

    const handleEditchangePasswordClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "changePassword") {
            closeCurrentPopup();
            setShowchangePasswordPopup(true);
            setCurrentPopup("changePassword");
        } else {
            setShowchangePasswordPopup(false);
            setCurrentPopup(null);
        }
    };

    const handleUpdateLoginClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "login") {
            closeCurrentPopup();
            setShowLoginPopup(true);
            setCurrentPopup("login");
        } else {
            setShowLoginPopup(false);
            setCurrentPopup(null);
        }
    };

    const handleEditSignUpClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "signUp") {
            closeCurrentPopup();
            setShowSignUpPopup(true);
            setCurrentPopup("signUp");
        } else {
            setShowSignUpPopup(false);
            setCurrentPopup(null);
        }
    };

    const handleEditDemoClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "demo") {
            closeCurrentPopup();
            setShowDemoPopup(true);
            setCurrentPopup("demo");
        } else {
            setShowDemoPopup(false);
            setCurrentPopup(null);
        }
    };

    const handleEditForgotpassClick = (e) => {
        e.preventDefault();
        if (currentPopup !== "forgotPass") {
            closeCurrentPopup();
            setShowForgotpassPopup(true);
            setCurrentPopup("forgotPass");
        } else {
            setShowForgotpassPopup(false);
            setCurrentPopup(null);
        }
    };

    // ----------------password--------------------
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = (e) => {
        e.preventDefault()
        setPasswordVisible(prev => !prev);
    };

    // ----------------option------------------------

    return (
        <header className='m-header  theme-color shadow-color text-white dark:text-white sticky top-0 z-40 w-full'>
            <div className='container mx-auto'>

                <div className='flex items-center justify-between pt-2 md:pt-0'>

                    {/* Mobile Header Start */}
                    <div className='relative' ref={PaymentRef2}>
                        <div onClick={togglePayment2} className='rounded-r-full tracking-wide drop-shadow-md bg-green-800 block ps-3 pe-3 py-1 text-sm font-semibold md:hidden'>FINR <span className='font-normal'>185</span> </div>
                    </div>
                    <div className='flex items-center'>
                        <div className='w-40 md:mr-10'>
                            <Link to={'/'}><img src='images/assets/logo.png' alt='' /></Link>
                        </div>
                    </div>
                    <div className='relative' ref={dropdownRef2}>
                        <div onClick={toggleDropdown2} className='rounded-l-full tracking-wide drop-shadow-md bg-green-800 ps-2 pe-3 py-1 text-xs uppercase flex items-center md:hidden'> <span className='me-1 pr-1 border-gray-500  border-r'><FaUserCircle /></span> Profile</div>
                    </div>
                    <div className='w-full hidden md:block'>
                        <ul className='flex'>
                            <Link to={'/sports'} className='text-xs uppercase text-slate-200 leading-7 py-2 px-4 hover:bg-slate-700 hover:text-white'>Sports</Link>
                            <Link to={'/in-play'} className='text-xs uppercase text-slate-200 leading-7 py-2 px-4 hover:bg-slate-700 hover:text-white'>In-play</Link>
                            <Link to={'/casino'} className='text-xs uppercase text-slate-200 leading-7 py-2 px-4 hover:bg-slate-700 hover:text-white blink-text-animation'>Indian Casino</Link>
                            <Link to={'/casino'} className='text-xs uppercase text-slate-200 leading-7 py-2 px-4 hover:bg-slate-700 hover:text-white'>Live Casino</Link>
                            <Link to={'/casino'} className=' text-xs uppercase text-slate-200 leading-7 py-2 px-4 hover:bg-slate-700 hover:text-white'>Slots</Link>
                        </ul>
                    </div>

                    <div className='w-100 me-2 md:w-1/2   hidden md:block  '>
                        <div className='flex justify-end items-center bg-slate-800 me-2 rounded row-reverse md:bg-transparent'>

                            {/* ----------------------After login----------------------------- */}
                            {/* <div className='flex flex-row items-center'>

                                <div className='mx-1 bg-[rgba(22,156,89,.2)] px-1.5 rounded-md'>
                                    <Link to={'/deposit'} type='button' className='inline-flex   text-white w-full justify-center gap-x-1.5 rounded-md px-1.5 py-1.5   uppercase font-medium m-0' > D
                                    </Link>
                                </div>
                                <div className='mx-1 bg-[rgba(0,143,255,.2)] px-1.5 rounded-md'>
                                    <Link to={'/withdraw'} type='button' className='inline-flex  text-white w-full justify-center gap-x-1.5 rounded-md px-1.5 py-1.5   uppercase font-medium m-0' > W
                                    </Link>
                                </div>
                                <div className='mx-1 bg-[rgba(22,156,89,.2)] rounded-md'>
                                    <Link to={'/crypto'} type='button' className='inline-flex   text-white w-full justify-center gap-x-1.5 rounded-md px-2.5 py-2.5   uppercase font-medium m-0' > <FaBitcoinSign />
                                    </Link>
                                </div>
                                <div className='relative' ref={PaymentRef}>
                                    <div onClick={togglePayment} className='mx-1 flex items-center cursor-pointer bg-[#32383e] rounded-md hover:bg-gray-800'>
                                        <button type='button' className='ml-2 inline-flex    text-white w-full justify-center gap-x-1.5 rounded-md px-1.5 py-1.5   uppercase font-bold my-0.5 items-center text-sm' >FINR 185
                                        </button><span className='p-2 bg-[#169c59] rounded'><FaPlus className='font-bold my-0.5' /></span>
                                    </div>
                                </div>
                                <div className='relative' ref={dropdownRef}>
                                    <div className='mx-1'>
                                        <button type='button' onClick={toggleDropdown} className='inline-flex bg-[#32383e] hover:bg-gray-800  text-white w-full justify-center gap-x-1.5 rounded-md px-2.5 py-2.5   uppercase font-medium m-0' > <FaUser />
                                        </button>
                                    </div>

                                </div>
                            </div> */}


                            {/* ----------------------login----------------------------- */}
                            <div className='flex flex-row items-center'>
                                <div className='relative' >

                                    <div onClick={handleUpdateLoginClick} className='mx-1 flex items-center cursor-pointer  px-3 py-0.5'>
                                        <button type='button' className='inline-flex     text-[#cfd4d8] w-full justify-center rounded-md px-1.5 py-1.5   uppercase font-bold   items-center text-sm' >LOG IN
                                        </button>
                                    </div>

                                </div>
                                <div className='relative' >

                                    <div onClick={handleEditSignUpClick} className='mx-1 flex items-center cursor-pointer bg-[#169c59]  rounded-md hover:bg-[#0d8247] px-3 py-0.5'>
                                        <button type='button' className='inline-flex     text-white w-full justify-center rounded-md px-1.5 py-1.5   uppercase font-bold   items-center text-sm' >SIGN UP
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ===================for mobile screen--------------------- */}
            {/* Logo Mobile Start  */}
            <div className='md:hidden flex justify-between items-center pt-1 pb-1'>
                <div className='flex items-center text-sm uppercase text-white mx-2'>
                    Welcome
                </div>
                <div className=' flex justify-center place-items-center'>
                    <div onClick={handleUpdateLoginClick} className='flex items-center text-sm uppercase text-gray-200 mx-2'>
                        Log in
                    </div>
                    <div onClick={handleEditSignUpClick} className='flex items-center text-sm uppercase text-gray-200 mx-2  bg-green-600 px-3 py-2 rounded-md'>
                        Sign Up
                    </div>
                </div>
            </div>
            {/* Logo Mobile End   */}
            {/* Deposit Mobile Section Start  */}
            {/* ------------------after login menu------------------ */}
            <div className='md:hidden block flex justify-center items-center pt-2 pb-2'>
                <Link to={'/crypto'} className='flex items-center text-sm uppercase text-gray-200 mx-2'>
                    <span className='mr-1'><FaBitcoinSign /></span>Crypto
                </Link>
                <Link to={'/deposit'} className='flex items-center text-sm uppercase text-gray-200 mx-2'>
                    <span className='mr-1'><MdInput /></span>Deposit
                </Link>
                <Link to={'/withdraw'} className='flex items-center text-sm uppercase text-gray-200 mx-2'>
                    <span className='mr-1'><MdLogout /></span>Withdraw
                </Link>
                <Link to={'/my-market'} className='flex items-center text-sm uppercase text-gray-200 mx-2'>
                    <span className='mr-1'><MdOutlineLeaderboard /></span>My Market
                </Link>
            </div>
            {/* Deposit Mobile Section End */}
            {/* ===================for mobile screen--------------------- */}
            {/* ----------------------------Payment----------------------- */}
            {isPaymentOpen && (
                <div className='absolute right-1 z-10 mt-2 w-64 origin-top-right divide-y rounded-md gradiant-bg-custom transition-all delay-700 ease-in-out'>
                    <div className='p-2'>
                        <p className='text-center mb-3'>Balance</p>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Credit Limit</span>
                            <span className='ml-auto'>FINR 5,000</span>
                        </div>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Balance</span>
                            <span className='ml-auto'>FINR -4,815</span>
                        </div>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Exposure</span>
                            <span className='ml-auto'>FINR 0</span>
                        </div>
                    </div>

                </div>)}
            {isPaymentOpen2 && (
                <div className='absolute left-1 top-12 z-10 mt-2 w-64  divide-y rounded-lg gradiant-bg-custom transition-all delay-700 ease-in-out'>
                    <div className='p-2'>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Credit Limit</span>
                            <span className='ml-auto'>FINR 5,000</span>
                        </div>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Balance</span>
                            <span className='ml-auto'>FINR -4,815</span>
                        </div>
                        <div className='flex items-center py-2 px-3'>
                            <span className='text-xs'>Exposure</span>
                            <span className='ml-auto'>FINR 0</span>
                        </div>
                    </div>

                </div>)}
            {/* --------------------------profile------------------------ */}
            {isDropdownOpen && (
                <div className='absolute right-0 z-10 mt-2 w-64 origin-top-right divide-y rounded-md gradiant-bg-custom transition-all delay-700 ease-in-out'>
                    <div className='p-2'>
                        <Link to={'/account'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><FaUserCircle /></span> <span className='ml-2'>My Profile</span> </Link>
                        <Link to={'/account-statement'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><HiCash /></span> <span className='ml-2'>Account Statement</span> </Link>
                        <Link to={'/bets/currentbets'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><HiCash /></span> <span className='ml-2'>My Bets</span> </Link>
                        <Link to={'/bets/betprofitloss'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><BiLineChart /></span> <span className='ml-2'>Profit Loss</span> </Link>
                        <Link to={'/bets/casinoprofit-loss'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><BiLineChart /></span> <span className='ml-2'>Casino Profit Loss</span> </Link>
                        <Link to={'/bonus-statement'} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><HiCash /></span> <span className='ml-2'>Bonus Statement</span> </Link>
                        <Link onClick={handleUpdateChipsSettingClick} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><BiChip /></span> <span className='ml-2'>Chips Setting</span> </Link>
                        <Link onClick={handleEditchangePasswordClick} className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><MdPublishedWithChanges /></span> <span className='ml-2'>Change Password</span> </Link>
                        <Link className='px-4 py-2.5 text-sm flex items-center rounded-md  transition-all delay-300 ease-in-out hover:bg-[#000]' ><span className='mb-0.5'><TbLogout /></span> <span className='ml-2'>LogOut</span> </Link>
                    </div>

                </div>)}
            {/* --------------------------profile-mobile------------------------ */}
            {isDropdownOpen2 && (
                <div className='absolute right-0 top-12 z-10 mt-2 w-64 origin-top-right divide-y rounded-lg mr-1.5 backdrop-blur-xl  bg-[#000000b3] transition-all delay-700 ease-in-out'>
                    <div className='p-2'>
                        <Link to={'/account'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><FaUserCircle /></span> <span className='ml-2'>My Profile</span> </Link>
                        <Link to={'/account-statement'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><HiCash /></span> <span className='ml-2'>Account Statement</span> </Link>
                        <Link to={'/bets/currentbets'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><HiCash /></span> <span className='ml-2'>My Bets</span> </Link>
                        <Link to={'/bets/betprofitloss'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><BiLineChart /></span> <span className='ml-2'>Profit Loss</span> </Link>
                        <Link to={'/bets/betshistory'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><HiCash /></span> <span className='ml-2'>Bet Hestory</span> </Link>
                        <Link to={'/bets/casinoprofit-loss'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><BiLineChart /></span> <span className='ml-2'>Casino Profit Loss</span> </Link>
                        <Link to={'/bonus-statement'} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><HiCash /></span> <span className='ml-2'>Bonus Statement</span> </Link>
                        <Link onClick={handleUpdateChipsSettingClick} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><BiChip /></span> <span className='ml-2'>Chips Setting</span> </Link>
                        <Link onClick={handleEditchangePasswordClick} className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#169c59]'><MdPublishedWithChanges /></span> <span className='ml-2'>Change Password</span> </Link>
                        <Link className='px-4 py-2.5  flex items-center rounded-md  transition-all delay-300 ease-in-out ' ><span className='mb-0.5 text-[#DC143C]'><TbLogout /></span> <span className='ml-2'>LogOut</span> </Link>
                    </div>

                </div>)}
            {/* --------------------------changepwd------------------- */}
            {showchangePasswordPopup && (
                <div className='fixed top-0 left-0 w-full mt-12 flex z-50 justify-center bg-[#000000b3] min-h-screen'>
                    <div className=' text-white w-[20rem] md:w-[25rem] lg:w-[30rem]'>
                        <div className='bg-black relative  p-4  pb-10'>
                            <h2 className='text-xl  '>Change Password</h2>
                            <button className='text-lg absolute top-4 right-4' onClick={closeCurrentPopup}>✕</button>
                        </div>

                        <div className='bg-[#0b0d0e]'>
                            <form className=' leading-10 p-4'>
                                <div className='pb-3'>
                                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter Current Password' />
                                </div>
                                <div className='pb-3'>
                                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter New Password' />
                                </div>
                                <div className='pb-3'>
                                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter Confirm Password' />
                                </div>
                                <div className='ml-auto mt-4 flex items-center flex-row justify-end'>
                                    <button type='button' className='bg-[#169c59] hover:bg-[#0d8247] font-bold p-5 py-0 rounded'>Save</button>
                                    <button type='button' className='bg-[#6c757d] hover:bg-[#5a6268] font-bold ml-4 p-5 py-0 rounded' onClick={closeCurrentPopup}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {/* --------------------------ChipsSetting------------------- */}
            {showChipsSettingPopup && (
                <div className='fixed top-0 left-0 z-50 w-full mt-12 flex justify-center bg-[#000000b3] min-h-screen'>
                    <div className=' text-white w-[20rem] md:w-[25rem] lg:w-[30rem]'>
                        <div className='bg-black relative  p-4   pb-10'>
                            <h2 className='text-xl  '>Chips Setting</h2>
                            <button className='text-lg absolute top-4 right-4' onClick={closeCurrentPopup}>✕</button>
                        </div>
                        <div className='bg-[#0b0d0e]'>
                            <form className=' leading-10 p-4'>
                                <div className='pb-3 grid md:grid-cols-2 grid-cols-1 gap-4'>
                                    <input type="text" value={5000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                    <input type="text" value={25000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                    <input type="text" value={50000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                    <input type="text" value={100000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                    <input type="text" value={200000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                    <input type="text" value={5000000} className='w-full border-b m border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' />
                                </div>

                                <div className='ml-auto mt-4 flex items-center flex-row justify-end'>
                                    <button type='button' className='bg-[#169c59] hover:bg-[#0d8247] font-bold p-5 py-0 rounded'>Save</button>
                                    <button type='button' className='bg-[#6c757d] hover:bg-[#5a6268] font-bold ml-4 p-5 py-0 rounded' onClick={closeCurrentPopup}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* ----------------------login-------------------------- */}
            {showLoginPopup && (
                <div className="fixed inset-0 bg-[#e1e1e11a] bg-opacity-50 flex justify-center items-center backdrop-blur-md">
                    <div className="bg-[#0b0d0e] text-white rounded-lg p-4 w-[20rem] md:w-[20rem] lg:w-[25rem]">
                        <div className=" mb-6 relative">
                            <h2 className="text-lg text-center mx-auto font-bold">Login</h2>
                            <button className="bg-[#169c59] rounded-full absolute top-0 right-0 text-black px-2 py-1 font-bold text-sm" onClick={closeCurrentPopup}>✕</button>
                        </div>
                        <form className=''>

                            <div className="my-4">
                                <div className="flex items-center rounded">
                                    <select className=" text-white  px-3.5 py-3.5 outline-none mr-3 text-sm bg-[#22262a]">
                                        <option>+91</option>
                                        <option>+91</option>
                                    </select>
                                    <span className=' p-2 py-3.5 bg-[#22262a]  flex-1 flex items-center'>
                                        <input type="text" placeholder="Mobile*" className="bg-[#22262a] text-white text-sm outline-none ml-2 " />
                                        <span className='ml-auto'><FaMobile /> </span>
                                    </span>
                                </div>
                            </div>

                            <div className="mb-4 flex items-center bg-[#22262a] rounded">
                                <input type={passwordVisible ? "text" : "password"} placeholder="Password *" className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />

                                <button
                                    className="  focus:outline-none mr-2 "
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <FaEye />
                                    )}
                                </button>
                            </div>
                            <div className="mb-3 bg-[#22262a] flex items-center rounded">
                                <input type="text" placeholder="Captcha" className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />

                                <div className='ml-auto flex items-center mr-2'>
                                    <span>
                                        <img src={captcha} className='rounded bg-white p-1 py-2' alt="" />
                                    </span>
                                    <span className='ml-2'>
                                        <FiRefreshCcw />
                                    </span>
                                </div>
                            </div>
                            <div className="mb-2 text-end " >
                                <button onClick={handleEditForgotpassClick}>
                                    <label className='text-sm cursor-pointer'>Forget Password ?</label>
                                </button>
                            </div>
                            <button type="button" className="w-full bg-[#169c59] text-white font-bold py-3 px-4 rounded">
                                LOG IN
                            </button>
                            <div className="mb-2 text-center">
                                <label className='text-sm'>Easy bet place on multiple sports,casino with our mobile application</label>
                            </div>
                            <button type="button" onClick={handleEditDemoClick} className="w-full bg-[#169c59] text-white font-bold py-3 px-4 rounded">
                                DEMO LOGIN
                            </button>
                            <button type="button" className="w-full flex items-center justify-center bg-[#32383e] text-white font-bold py-2 mt-3 rounded">
                                DOWNLOAD APK <IoLogoAndroid className='ml-2' />
                            </button>
                            <div className="my-3 mb-0 text-center ">
                                <label className='text-sm'>Don't have account? <span onClick={handleEditSignUpClick} className='text-[#169c59] hover:text-white cursor-pointer font-semibold'>Join Now</span></label>
                            </div>

                        </form>
                    </div>
                </div>)}

            {/* ----------------------------signup------------------------- */}
            {showSignUpPopup && (
                <div className="fixed inset-0 bg-[#e1e1e11a] bg-opacity-50 flex justify-center items-center backdrop-blur-md">
                    <div className="bg-[#0b0d0e] text-white rounded-lg p-6 w-[20rem] md:w-[20rem] lg:w-[25rem]">
                        <div className=" mb-6 relative">
                            <h2 className="text-lg text-center mx-auto font-bold">Sign Up</h2>
                            <button className="bg-[#169c59] rounded-full absolute top-0 right-0 text-black px-2 py-1 font-bold text-sm" onClick={closeCurrentPopup}>✕</button>
                        </div>
                        <form className=''>

                            <div className="my-4">
                                <div className="flex items-center rounded">
                                    <select className=" text-white  p-2 py-3.5 outline-none mr-3 text-sm bg-[#22262a]">
                                        <option>+91</option>
                                    </select>
                                    <span className=' p-2 py-3.5 bg-[#22262a]  flex-1 flex items-center'>
                                        <input type="text" placeholder="Mobile*" className="bg-[#22262a] text-white text-sm outline-none ml-2 " />
                                        <span className='ml-auto'><FaMobile /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-4 flex items-center">
                                <input type="radio" id="otpWhatsapp" className="mr-2" />
                                <label className='text-sm'>Receive OTP via Whatsapp</label>
                            </div>

                            <div className="mb-6">
                                <input type="text" placeholder="Enter OTP" className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />
                            </div>
                            <button type="button" className="w-full bg-[#169c59] text-white font-bold py-3 px-4 rounded">
                                JOIN NOW
                            </button>
                            <div className="my-3 text-center">
                                <label className='text-sm'>Already have account? <span onClick={handleUpdateLoginClick} className='text-[#169c59] cursor-pointer font-semibold'>Login</span></label>
                            </div>

                            <button type="button" className="w-full bg-[#169c59] text-white font-bold py-1.5 blink-text-animation px-4 rounded flex items-center justify-center">
                                <FaWhatsapp className='mr-2 text-4xl' />Get id easily on whatsapp!
                            </button>
                        </form>
                    </div>
                </div>)}
            {/* ---------------------demo---------------------- */}
            {showDemoPopup && (
                <div className="fixed inset-0 bg-[#e1e1e11a] bg-opacity-50 flex justify-center items-center backdrop-blur-md">
                    <div className="bg-[#0b0d0e] text-white rounded-lg p-4 w-[20rem] md:w-[20rem] lg:w-[25rem]">
                        <div className=" mb-6 relative">
                            <h2 className="text-lg text-center mx-auto font-bold">Demo Login</h2>
                            <button className="bg-[#169c59] rounded-full absolute top-0 right-0 text-black px-2 py-1 font-bold text-sm" onClick={closeCurrentPopup}>✕</button>
                        </div>
                        <form className=''>


                            <div className="mb-3 bg-[#22262a] flex items-center rounded">
                                <input type="text" placeholder="Captcha" className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />

                                <div className='ml-auto flex items-center mr-2'>
                                    <span>
                                        <img src={captcha} className='rounded bg-white p-1 py-2' alt="" />
                                    </span>
                                    <span className='ml-2'>
                                        <FiRefreshCcw />
                                    </span>
                                </div>
                            </div>

                            <button type="button" className="w-full bg-[#169c59] text-white font-bold py-3 px-4 rounded">
                                LOG IN
                            </button>
                            <div className="my-3 mb-0 text-center ">
                                <label className='text-sm'>Already have account? <span onClick={handleUpdateLoginClick} className='text-[#169c59] hover:text-white cursor-pointer font-semibold'>Login Now</span></label>
                            </div>

                        </form>
                    </div>
                </div>)}

            {/* ----------------------forgetpassword-------------------------- */}
            {showForgotpassPopup && (
                <div className="fixed inset-0 bg-[#e1e1e11a] bg-opacity-50 flex justify-center items-center backdrop-blur-md">
                    <div className="bg-[#0b0d0e] text-white rounded-lg p-4 w-[20rem] md:w-[20rem] lg:w-[25rem]">
                        <div className=" mb-6 relative">
                            <h2 className="text-lg text-center mx-auto font-bold">Reset Password</h2>
                            <button className="bg-[#169c59] rounded-full absolute top-0 right-0 text-black px-2 py-1 font-bold text-sm" onClick={closeCurrentPopup}>✕</button>
                        </div>
                        <form className=''>

                            <div className="my-4">
                                <div className="flex items-center rounded">
                                    <select className=" text-white  px-2 py-3.5 outline-none mr-3 text-sm bg-[#22262a]">
                                        <option>+91(india)</option>
                                        <option>+91</option>
                                    </select>
                                    <span className=' p-2 py-3.5 bg-[#22262a]  flex-1 flex items-center'>
                                        <input type="text" placeholder="Mobile*" className="bg-[#22262a] text-white text-sm outline-none ml-2 " />
                                        <span className='ml-auto'><FaMobile /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-4 flex items-center">
                                <input type="radio" id="otpWhatsapp" className="mr-2" />
                                <label className='text-sm'>Receive OTP via Whatsapp</label>
                            </div>
                            <div className="mb-4">
                                <input type="text" placeholder="Enter OTP" className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />
                            </div>
                            <div className="mb-4 flex items-center bg-[#22262a] rounded">
                                <input type={passwordVisible ? "text" : "password"} placeholder="New Password *" disabled className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />

                                <button
                                    className="  focus:outline-none mr-2 "
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <FaEye />
                                    )}
                                </button>
                            </div>
                            <div className="mb-4 flex items-center bg-[#22262a] rounded">
                                <input type={passwordVisible ? "text" : "password"} placeholder="Confirm Password *" disabled className="py-3.5 text-sm text-white w-full bg-[#22262a]  p-2 rounded outline-none" />

                                <button
                                    className="  focus:outline-none mr-2 "
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <FaEye />
                                    )}
                                </button>
                            </div>

                            <button type="button" className="w-full bg-[#169c59] text-white font-bold py-3 px-4 rounded">
                                RESET PASSWORD
                            </button>

                            <div className="my-3 mb-0 text-start ">
                                <label className='text-sm'>Already have an account? <span onClick={handleUpdateLoginClick} className='text-white cursor-pointer font-semibold'>Login here</span></label>
                            </div>
                            <div className="my-3 mb-0 text-start flex items-center">
                                <span className='text-sm'>Get your ready-made ID</span>
                                <span className='text-white bg-[#22270c] py-0.5 px-2 ml-3 rounded cursor-pointer font-semibold flex items-center text-sm'><FaWhatsapp className='mr-2' />Whatshapp Now</span>
                            </div>

                        </form>
                    </div>
                </div>)}
        </header >

    )
}





export default Header


