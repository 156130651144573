import React from 'react'

const MyMarket = () => {
    return (
        <div className='max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a]  ml-2 md:ml-0 mr-0.5 md:mr-0     py-3.5 pt-2 rounded px-5'>
                <p className='text-center text-lg font-bold border-b pb-2 border-zinc-700'>MY MARKET</p>
                <br /><div className='mb-5'></div>
            </div>
        </div>
    )
}

export default MyMarket