
import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { IoMdStopwatch } from 'react-icons/io'
import { MdHome, MdOutlineCasino } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
    const location = useLocation();

    // Function to check if a given path is the current path
    const isActive = (path) => {
        return location.pathname === path;
    }

    return (
        <div className='container mx-auto'>
            <div className='fixed bottom-3 w-full md:hidden z-40'>
                <ul className='bg-[#32383e] rounded-full flex gap-2 items-center justify-between mx-4 px-1 py-1'>
                    <Link to={'/'} className={`w-1/4 rounded-full flex items-center justify-center flex-col py-1 text-white text-sm ${isActive('/') ? 'bg-green-700' : ''}`}>
                        <span className='text-xl'><MdHome /></span>Home
                    </Link>

                    <Link to={'/sports'} className={`w-1/4 rounded-full flex items-center justify-center flex-col py-1 text-white text-sm ${isActive('/sports') ? 'bg-green-700' : ''}`}>
                        <span className='text-xl mb-1'><FaCalendarAlt /></span>Sports
                    </Link>

                    <Link to={'/in-play'} className={`w-1/4 rounded-full flex items-center justify-center flex-col py-1 text-white text-sm ${isActive('/in-play') ? 'bg-green-700' : ''}`}>
                        <span className='text-xl mb-1'><IoMdStopwatch /></span> In-Play
                    </Link>

                    <Link to={'/casino'} className={`w-1/4 rounded-full flex items-center justify-center flex-col py-1 text-white text-sm ${isActive('/casino') ? 'bg-green-700' : ''}`}>
                        <span className='text-xl mb-1'><MdOutlineCasino /></span> Casino
                    </Link>
                </ul>
            </div>
        </div>
    )
}

export default Footer
