import React, { useState } from 'react'
import { FaList } from 'react-icons/fa'

const Crypto = () => {
    const [showStatusPopup, setShowStatusPopup] = useState(false);
    const [showofferPopup, setShowofferPopup] = useState(false);

    const handleUpdateStatusClick = () => {
        setShowStatusPopup(true);
    };

    const handleEditofferClick = () => {
        setShowofferPopup(true);
    };

    const handleCloseStatusPopup = () => {
        setShowStatusPopup(false);
    };

    const handleCloseofferPopup = () => {
        setShowofferPopup(false);
    };
    return (
        <div className='max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a]  ml-2 md:ml-0 mr-0.5 md:mr-0     py-3.5 pt-2 rounded px-5'>
                <p className='text-center text-lg font-bold   pb-2 '>Crypto Deposit</p>

                <div className='bg-[#383d44] p-5'>
                    <div className='flex items-center border-b pb-2 border-[#0f232766]'>
                        <p className='text-[#ccc] font-bold'>Deposit By Crypto</p>
                        <p onClick={handleUpdateStatusClick} className='cursor-pointer text-[#169c59] text-sm ml-auto'>View Request Status</p>
                    </div>
                    <div className='flex items-center mt-5 '>
                        <p className='text-white text-sm'>Amount to deposit  *</p>
                        <p className='text-white text-sm ml-auto'>Min. 2,000 || Max. 300,000</p>
                    </div>
                    <input type="text" placeholder='Amount' className='w-full bg-[#22262a] text-sm p-1 rounded py-1.5 mt-2' name="" id="" />
                    <div className='grid grid-cols-3 gap-3 my-3'>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+100.00</div>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+500.00</div>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+1000.00</div>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+5000.00</div>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+10000.00</div>
                        <div className='bg-[#169c59] text-center text-xs rounded font-bold p-2'>+50000.00</div>
                    </div>
                    <div className='my-3'>
                        <div className='flex items-center  3'>
                            <p className='text-white text-sm'>Select & Apply Offer</p>
                            <p onClick={handleEditofferClick} className='cursor-pointer text-[#ff4500] text-sm ml-auto flex items-center'><FaList className='text-sm mr-1' />Offers</p>
                        </div>
                        <input type="text" placeholder='Apply code' className='w-full bg-[#22262a] text-sm p-1 rounded py-1.5' name="" id="" />
                    </div>
                    <button className='bg-[#4f9773] w-full p-2 mb-3'>Deposit</button>

                </div>
            </div>

            {showStatusPopup && (
                <div className='fixed top-0 z-50 left-0 w-full mt-12 flex justify-center bg-[#000000b3] min-h-screen'>
                    <div className=' text-white w-[20rem] md:w-[40rem] lg:w-[50rem]'>
                        <div className='bg-black relative p-4  flex justify-between items-center pb-10'>
                            <h2 className='text-[#ccc]'>Recent Crypto Deposit Requests</h2>
                            <button className='text-lg absolute top-4 right-4' onClick={handleCloseStatusPopup}>✕</button>
                        </div>
                        <div className='bg-[#0b0d0e] p-2 '>
                            <div className='overflow-x-auto '>

                                <table className='table-auto overflow-y-scroll w-full'>
                                    <tr className='border-b-2 border-t-2 border-[#4c555e] py-2'>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Invoice Id</th>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Invoice Url</th>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Amount</th>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Bonus</th>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Status</th>
                                        <th className='w-1/6 text-start px-2 py-2 whitespace-nowrap'>Created On</th>
                                    </tr>
                                    <tr className='border-b-2 border-[#4c555e87] text-sm'>
                                        <td className='p-2'>65466545</td>
                                        <td className='p-2'>https://nowpayments.io/</td>
                                        <td className='p-2'>2,000</td>
                                        <td className='p-2'>0</td>
                                        <td className='p-2'>Init</td>
                                        <td className='p-2'>28 Apr21:28:13</td>
                                    </tr>
                                    <tr className='border-b-2 border-[#4c555e87] text-sm'>
                                        <td className='p-2'>65466545</td>
                                        <td className='p-2'>https://nowpayments.io/</td>
                                        <td className='p-2'>2,000</td>
                                        <td className='p-2'>0</td>
                                        <td className='p-2'>Init</td>
                                        <td className='p-2'>28 Apr21:28:13</td>
                                    </tr>
                                    <tr className='border-b-2 border-[#4c555e87] text-sm'>
                                        <td className='p-2'>65466545</td>
                                        <td className='p-2'>https://nowpayments.io/</td>
                                        <td className='p-2'>2,000</td>
                                        <td className='p-2'>0</td>
                                        <td className='p-2'>Init</td>
                                        <td className='p-2'>28 Apr21:28:13</td>
                                    </tr>
                                    <tr className='border-b-2 border-[#4c555e87] text-sm'>
                                        <td className='p-2'>65466545</td>
                                        <td className='p-2'>https://nowpayments.io/</td>
                                        <td className='p-2'>2,000</td>
                                        <td className='p-2'>0</td>
                                        <td className='p-2'>Init</td>
                                        <td className='p-2'>28 Apr21:28:13</td>
                                    </tr>
                                </table>
                            </div>
                            <div className='ml-auto my-4  flex items-center flex-row justify-end'>
                                <button type='button' className='bg-[#6c757d] hover:bg-[#5a6268] font-bold ml-4 p-5 py-2 mr-4 rounded' onClick={handleCloseStatusPopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showofferPopup && (
                <div className='fixed top-0 z-50 left-0 w-full mt-12 flex justify-center bg-[#000000b3] min-h-screen'>
                    <div className=' text-white w-[20rem] md:w-[40rem] lg:w-[50rem]'>
                        <div className='bg-black relative p-4  flex justify-between items-center pb-10'>
                            <h2 className='text-[#ccc] text-xl'>Book365 Offers</h2>
                            <button className='text-lg absolute top-4 right-4' onClick={handleCloseofferPopup}>✕</button>
                        </div>
                        <div className='bg-[#0b0d0e] p-2 '>
                            <div className='grid md:grid-cols-6 md:gap-4 grid-cols-1 border border-[#ededed] bg-[#383d44] p-2 mb-4 rounded'>
                                <div className='col-span-1 my-auto'>
                                    <p className='p-2 bg-[#000] py-1.5 text-center rounded-md'>DEPOSIT5</p>
                                </div>
                                <div className='col-span-4 my-2'>
                                    <p>5% BONUS ON 1st Deposit</p>
                                    <p className='text-sm text-[#ccc]'>NOTE:- A 6x turnover of the bonus amount must be completed to activate the bonus.</p>
                                    <p className='text-sm text-[#ccc]'>NOTE:- Maximum Bonus you can receive for First Deposit Bonus is 500</p>
                                    <p className='text-sm text-[#ccc]'>In casino and card games, the turnover will be calculate based on P&L instead of Bet amount.</p>
                                    <p className='text-sm text-[#ccc]'>Turnover in Sports will be considered only if P&L amount will be greater than 50% of Bet amount.                                    </p>
                                    <p className='text-sm text-[#ccc]'>Promo code is required</p>
                                    <p className='text-sm text-[#ccc]'>This offer is applicable for First time deposit</p>
                                </div>
                                <div className='col-span-1 my-auto flex flex-col justify-center'>
                                    <p className='text-center'>Offer Percentage 5%</p>
                                    <button className='bg-[#169c59] mx-auto text-center mt-2 rounded w-auto font-bold px-4 py-2.5'>Select</button>

                                </div>

                            </div>
                            <div className='grid md:grid-cols-6 md:gap-4 grid-cols-1 border border-[#ededed] bg-[#383d44] p-2 mb-4 rounded'>
                                <div className='col-span-1 my-auto'>
                                    <p className='p-2 bg-[#000] py-1.5 text-center rounded-md'>DEPOSIT5</p>
                                </div>
                                <div className='col-span-4 my-2'>
                                    <p>5% BONUS ON 1st Deposit</p>
                                    <p className='text-sm text-[#ccc]'>NOTE:- A 6x turnover of the bonus amount must be completed to activate the bonus.</p>
                                    <p className='text-sm text-[#ccc]'>NOTE:- Maximum Bonus you can receive for First Deposit Bonus is 500</p>
                                    <p className='text-sm text-[#ccc]'>In casino and card games, the turnover will be calculate based on P&L instead of Bet amount.</p>
                                    <p className='text-sm text-[#ccc]'>Turnover in Sports will be considered only if P&L amount will be greater than 50% of Bet amount.                                    </p>
                                    <p className='text-sm text-[#ccc]'>Promo code is required</p>
                                    <p className='text-sm text-[#ccc]'>This offer is applicable for First time deposit</p>
                                </div>
                                <div className='col-span-1 my-auto flex flex-col justify-center'>
                                    <p className='text-center'>Offer Percentage 5%</p>
                                    <button className='bg-[#169c59] mx-auto text-center mt-2 rounded w-auto font-bold px-4 py-2.5'>Select</button>

                                </div>

                            </div>
                            <div className='ml-auto my-4  flex items-center flex-row justify-end'>
                                <button type='button' className='bg-[#6c757d] hover:bg-[#5a6268] font-bold ml-4 p-5 py-2 mr-4 rounded' onClick={handleCloseofferPopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Crypto