import React, { useState } from "react";
import { BiCricketBall, BiCycling, BiFootball, BiSolidBaseball } from "react-icons/bi";
import { FaChevronDown, FaChevronUp, FaCreditCard, FaVolleyballBall } from "react-icons/fa";
import { GiCricketBat, GiDart, GiHockey, GiHorseHead } from "react-icons/gi";
import { MdOutlineSportsHandball, MdOutlineSportsKabaddi, MdSportsEsports } from "react-icons/md";
import { RiGovernmentFill } from "react-icons/ri";
import { LiaTableTennisSolid } from "react-icons/lia";

const options = [
    {
        id: 1,
        label: "Cricket",
        count: "10",
        Icon: <GiCricketBat />,
        children: [
            {
                id: 2,
                label: "Indian Premier League",
                children: [
                    {
                        label: "Punjab Kings v Mumbai Indians"
                    },
                    {
                        label: "Punjab Kings v Mumbai Indians"
                    },
                    {
                        label: "Punjab Kings v Mumbai Indians"
                    },
                    {
                        label: "Punjab Kings v Mumbai Indians"
                    }
                ]
            },
            {
                id: 3,
                label: "International Twenty20 Matches",
                children: [
                    {
                        label: "Punjab Kings v Mumbai Indians"
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        label: "Football",
        count: "9",
        Icon: <BiFootball />,
        children: [
            {
                id: 5,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    }
                ]
            },
            {
                id: 6,
                label: "UEFA Europa Conference League",
                children: [
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                ]
            }
        ]
    },
    {
        id: 7,
        label: "Tennis",
        count: "3",
        Icon: <LiaTableTennisSolid />,
        children: [
            {
                id: 8,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    }
                ]
            },
            {
                id: 9,
                label: "UEFA Europa Conference League",
                children: [
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                ]
            }
        ]
    },
    {
        id: 10,
        label: "Horse Racing",
        Icon: <GiHorseHead />,
        children: [
            {
                id: 11,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    }
                ]
            },
            {
                id: 12,
                label: "UEFA Europa Conference League",
                children: [
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                    {
                        label: "Lille v Aston Villa"
                    },
                ]
            }
        ]
    },
    {
        id: 13,
        label: "Special Bets",
        Icon: <BiFootball />,
        children: [
            {
                id: 14,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    }
                ]
            },

        ]
    },
    {
        id: 15,
        label: "Politics",
        Icon: <RiGovernmentFill />,
        children: [
            {
                id: 16,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                ]
            },

        ]
    },
    {
        id: 17,
        label: "Kabaddi",
        Icon: <MdOutlineSportsKabaddi />,
        children: [
            {
                id: 18,
                label: "UEFA Europa League",
                children: [
                    {
                        label: "Atalanta v Liverpool"
                    },
                    {
                        label: "Atalanta v Liverpool"
                    },
                ]
            },

        ]
    },
    {
        id: 19,
        label: "Esports",
        Icon: <MdSportsEsports />,
        children: []
    },
    {
        id: 20,
        label: "Volleyball",
        Icon: <FaVolleyballBall />,
        children: []
    }, {
        id: 21,
        label: "Cycling",
        Icon: <BiCycling />,
        children: []
    }, {
        id: 22,
        label: "Snooker",
        Icon: <BiFootball />,
        children: []
    }, {
        id: 23,
        label: "Baseball",
        Icon: <BiSolidBaseball />,
        children: []
    }, {
        id: 24,
        label: "Netball",
        Icon: <BiFootball />,
        children: []
    }, {
        id: 25,
        label: "Ice Hockey",
        Icon: <GiHockey />,
        children: []
    }, {
        id: 26,
        label: "Handball",
        Icon: <MdOutlineSportsHandball />,
        children: []
    }, {
        id: 27,
        label: "Darts",
        Icon: <GiDart />,
        children: []
    }
];
function LeftSidebar() {
    const [isOpen, setIsOpen] = useState({});

    const toggleDropdown = (parentId, childId) => {
        // Check if the main option is already open
        const isMainOptionOpen = isOpen[parentId] !== undefined;
        const isChildOptionOpen = isOpen[childId] !== undefined;

        // Close all dropdowns if the main option is already open and clicked again
        if (isChildOptionOpen && isMainOptionOpen && isOpen[parentId][childId]) {
            setIsOpen({});
        } else {
            setIsOpen({
                ...isOpen,
                [parentId]: {
                    ...isOpen[parentId],
                    [childId]: !isOpen[parentId]?.[childId]
                }
            });
        }
    };




    return (
        <div className="col-span-2  max-h-screen overflow-y-scroll">
            <p className="bg-[#22262a] py-2 text-sm text-gray-200 px-2 mb-2 rounded">SPORTS</p>
            {options.map((option) => (
                <div key={option.id} className="relative text-xs text-gray-200">

                    {/* ================Main================ */}
                    <a onClick={() => { toggleDropdown(option.id, option.id) }} className={`text-sm text-gray-200 cursor-pointer   hover:bg-[#22262a]  ${option.children ? (isOpen[option.id] ? "bg-[#22262a]" : "") : null} py-3 roundedmb-1 px-2 flex items-center relative`}>
                        <span className="mr-2">{option.Icon}</span>  {option.label} <span className="ml-auto text-xs flex items-center">{option.count ? (<span className="bg-white rounded py-0.5 mr-3 px-2 text-black">{option.count}</span>) : ("")}{option.children ? (isOpen[option.id] ? <FaChevronUp /> : <FaChevronDown />) : null}</span>
                    </a>
                    {/* ================Inner-1================ */}
                    {isOpen[option.id] && Array.isArray(option.children) && option.children.length > 0 ? (
                        <div className="bg-[#22262a] py-2 rounded  ">
                            {option.children.map((child) => (
                                <div key={child.id}>
                                    <a onClick={() => toggleDropdown(option.id, child.id)} className={`${option.children ? (isOpen[option.id] ? "bg-gray-600" : "") : null} text-xs text-gray-200 hover:bg-gray-600 mx-2 cursor-pointer transition-all delay-300 ease-in-out bg-[#22262a] py-2 my-1 px-2 flex items-center`}>
                                        {child.label} <span className="ml-auto text-xs">{child.children ? (isOpen[option.id]?.[child.id] ? <FaChevronUp /> : <FaChevronDown />) : null}</span>
                                    </a>
                                    {/* ================Inner-2================ */}
                                    {isOpen[option.id]?.[child.id] && child.children && (
                                        <div className=" bg-gray-600 mx-2 py-1">
                                            {child.children.map((grandchild) => (
                                                <a key={grandchild.id} className="text-xs text-gray-200 cursor-pointer mx-1 transition-all delay-300 ease-in-out bg-transparent py-2 my-1 rounded px-2 flex items-center  ">
                                                    {grandchild.label}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : null}

                </div>
            ))}
        </div>
    );
}

export default LeftSidebar;