import React from 'react'
import { Slick } from "@slick-slider/slick";
import { IoIosArrowUp } from "react-icons/io";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Sidebar } from 'react-pro-sidebar'
import HomeSidebar1 from '../Header/LeftSidebar';
import sliderimage1 from '../../images/banner-1.jpg'
import sliderimage2 from '../../images/banner-2.jpg'
import sliderimage3 from '../../images/banner-3.jpg'
import sliderimage4 from '../../images/banner-4.jpg'
import sliderimage5 from '../../images/banner-5.jpg'
import sliderimage6 from '../../images/banner-6.jpg'
import sliderimage7 from '../../images/banner-7.jpg'
import sliderimage8 from '../../images/banner-8.jpg'
import HomeRightsidebar from '../Header/RightSidebar';
import { MdOutlineLiveTv } from 'react-icons/md';
import RightSidebar from '../Header/RightSidebar';
import { Link } from 'react-router-dom';

const images = [
  {
    url: sliderimage1,
  },
  {
    url: sliderimage2,
  },
  {
    url: sliderimage3,
  },
  {
    url: sliderimage4,
  },
  {
    url: sliderimage5,
  },
  {
    url: sliderimage6,
  },
  {
    url: sliderimage7,
  },
  {
    url: sliderimage8,
  },
];


export default function HomeLayout() {
  return (


    <div className='flex'>
      <div className='middle-content text-slate-50 max-h-screen overflow-y-scroll w-[100%] lg:w-[70%]'>
        <div className="px-1 md:px-0">
          <div className='theme-color px-2 pl-12 md:pl-12 py-3 text-white text-xs font-semibold rounded-md mb-2 relative'>
            <div className='absolute top-1 left-1 w-9'>
              <img className='' src='images/icon/loud-speaker.png' alt='' />
            </div>
            <marquee scrollamount="5" className="text-gray-400">Welcome to Book365...</marquee>
          </div>
          <div className=''>
            <div style={{ width: "100%", height: "100%", margin: "auto" }}>
              <Slick
                keyExtractor={(image) => image.url}
                urlExtractor={(image) => image.url}
                onClick={(image) => {
                  console.log('image', image)
                }}
                images={images}
                durationAnimation={500}
                durationAutoPlay={3000}
              />
            </div>
          </div>
          <div className='mt-2'>
            <Tabs>
              <TabList className='flex items-center justify-between gap-2'>
                <Tab className='flex theme-color hover:bg-gray-600 w-1/5 justify-center rounded-md pt-2 pb-1 cursor-pointer'>
                  <div className='flex items-center flex-col'>
                    <img className='w-5 mb-1' src='images/icon/sports.png' alt='' />
                    <h6 className='text-xs text-gray-200'> Sports</h6></div>
                </Tab>
                <Tab className='flex theme-color hover:bg-gray-600 w-1/5 justify-center rounded-md pt-2 pb-1 cursor-pointer'>
                  <div className='flex items-center flex-col'>
                    <img className='w-5 mb-1' src='images/icon/live.png' alt='' />
                    <h6 className='text-xs text-gray-200'>In-Play</h6></div>
                </Tab>
                <Tab className='flex theme-color hover:bg-gray-600 w-1/5 justify-center rounded-md pt-2 pb-1 cursor-pointer'>
                  <div className='flex items-center flex-col'>
                    <img className='w-5 mb-1' src='images/icon/indian-casino.png' alt='' />
                    <h6 className='text-xs text-gray-200'>Indian Casino</h6></div>
                </Tab>
                <Tab className='flex theme-color hover:bg-gray-600 w-1/5 justify-center rounded-md pt-2 pb-1 cursor-pointer'>
                  <div className='flex items-center flex-col'>
                    <img className='w-5 mb-1' src='images/icon/live-casino.png' alt='' />
                    <h6 className='text-xs text-gray-200'>Live Casino</h6></div>
                </Tab>
                <Tab className='flex theme-color hover:bg-gray-600 w-1/5 justify-center rounded-md pt-2 pb-1 cursor-pointer'>
                  <div className='flex items-center flex-col'>
                    <img className='w-5 mb-1' src='images/icon/slot.png' alt='' />
                    <h6 className='text-xs text-gray-200'>Slots</h6></div>
                </Tab>
              </TabList>

              <TabPanel>
                <div className='tab-middle-content pb-20 md:pb-0'>
                  <div className='flex items-center justify-between my-3'>
                    <div className='text-md text-gray-200 flex items-center'><span className='me-2'>Cricket</span>  <IoIosArrowUp /></div>
                    <div className='text-sm text-gray-200'>3 Events</div>
                  </div>
                  <div className='market-list'>
                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg'>
                      <div className='text-sm text-white flex items-center'><span>Indian Premier League</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>4</span>  <IoIosArrowUp /></div>
                    </div>

                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-green-700 rounded-full'><img className=' w-4 filter' src='../../../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Sunrisers Hyderabad</span>
                                <span className='block text-xs text-gray-200'>Royal Challengers Bengaluru</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <span className='bg-green-700  px-1 font-small text-white uppercase leading-3 rounded-sm'>Live</span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Mumbai Indians</span>
                                <span className='block text-xs text-gray-200'>Chennai Super Kings</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Sunrisers Hyderabad</span>
                                <span className='block text-xs text-gray-200'>Royal Challengers Bengaluru</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <span className='bg-green-700  px-1 font-small text-white uppercase leading-3 rounded-sm'>Live</span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Sunrisers Hyderabad</span>
                                <span className='block text-xs text-gray-200'>Royal Challengers Bengaluru</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <span className='bg-green-700  px-1 font-small text-white uppercase leading-3 rounded-sm'>Live</span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Delhi Capitals</span>
                                <span className='block text-xs text-gray-200'>Mumbai Indians</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <span className='bg-green-700  px-1 font-small text-white uppercase leading-3 rounded-sm'>Live</span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>

                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg mt-2'>
                      <div className='text-sm text-white flex items-center'><span>International Twenty20 Matches</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>1</span>  <IoIosArrowUp /></div>
                    </div>

                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-green-700 rounded-full'><img className=' w-4 filter' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Pakistan</span>
                                <span className='block text-xs text-gray-200'>New Zealand</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <span className='bg-green-700  px-1 font-small text-white uppercase leading-3 rounded-sm'>Live</span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>

                    <div className='flex items-center justify-between my-3'>
                      <div className='text-md text-gray-200 flex items-center'><span className='me-2'>Footbal</span>  <IoIosArrowUp /></div>
                      <div className='text-sm text-gray-200'>9 Events</div>
                    </div>

                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg mt-2'>
                      <div className='text-sm text-white flex items-center'><span>English Premier League</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>1</span>  <IoIosArrowUp /></div>
                    </div>

                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Brighton</span>
                                <span className='block text-xs text-gray-200'>Man City</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>

                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg mt-2'>
                      <div className='text-sm text-white flex items-center'><span>Dutch Eredivisie</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>2</span>  <IoIosArrowUp /></div>
                    </div>

                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Heerenveen</span>
                                <span className='block text-xs text-gray-200'>PSV</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Go Ahead Eagles</span>
                                <span className='block text-xs text-gray-200'>Feyenoord</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>

                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg mt-2'>
                      <div className='text-sm text-white flex items-center'><span>Swedish Allsvenskan</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>6</span>  <IoIosArrowUp /></div>
                    </div>

                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Vasteras SK</span>
                                <span className='block text-xs text-gray-200'>Mjallby</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Halmstads</span>
                                <span className='block text-xs text-gray-200'>Hammarby</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Norrkoping</span>
                                <span className='block text-xs text-gray-200'>Elfsborg</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>QPR</span>
                                <span className='block text-xs text-gray-200'>Leeds</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Djurgardens</span>
                                <span className='block text-xs text-gray-200'>Malmo FF</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>

                    <div className='flex items-center justify-between my-3'>
                      <div className='text-md text-gray-200 flex items-center'><span className='me-2'> Tennis</span>  <IoIosArrowUp /></div>
                      <div className='text-sm text-gray-200'>9 Events</div>
                    </div>
                    <div className='flex items-center justify-between  px-3 py-2 bg-[#32383e] rounded-t-lg'>
                      <div className='text-sm text-white flex items-center'><span>WTA Madrid 2024</span></div>
                      <div className='text-sm text-white  flex items-center'> <span className='me-1'>4</span>  <IoIosArrowUp /></div>
                    </div>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Vasteras SK</span>
                                <span className='block text-xs text-gray-200'>Mjallby</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Halmstads</span>
                                <span className='block text-xs text-gray-200'>Hammarby</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Norrkoping</span>
                                <span className='block text-xs text-gray-200'>Elfsborg</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>QPR</span>
                                <span className='block text-xs text-gray-200'>Leeds</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                    <Link to={'/event'} className='lg:flex items-center theme-color justify-between px-3 py-1 lg:py-2 gap-2 border-b border-stone-950  block'>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='flex items-center w-full justify-between gap-1'>
                          <div className='flex items-center justify-between md:w-2/3 '>
                            <div className='flex items-center'>
                              <span className='px-2 py-2 bg-white rounded-full'><img className=' w-4' src='../../images/icon/Cricket.png' alt='' /></span>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Djurgardens</span>
                                <span className='block text-xs text-gray-200'>Malmo FF</span>
                              </div>
                            </div>
                          </div>
                          <div className='flex items-center justify-between md:w-1/3'>
                            <div className='flex items-center'>
                              <div className='ms-4'>
                                <span className='block text-xs text-gray-200'>Today 19:30</span>
                                <div className='flex items-center justify-end w-full'>
                                  <span className='text-green-300'><MdOutlineLiveTv /></span>
                                  <span className='ml-3 bg-white text-black py-0 leading-3 px-1 font-small rounded-sm mt-1'>
                                    F
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-between w-full  py-2 md:py-0 md:w-1/2'>
                        <div className='grid grid-cols-6 w-full gap-1'>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>1.11</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>1.12</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>-</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold back-text'>12.00</div>
                          <div className='bg-[#32383e] text-xs text-gray-200 px-2 py-2 rounded-md text-center font-bold lay-text'>13.05</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <h2>In-Play</h2>
              </TabPanel>
              <TabPanel>
                <h2>Indian Casino</h2>
              </TabPanel>
              <TabPanel>
                <h2>Live Casino</h2>
              </TabPanel>
              <TabPanel>
                <h2>Slots</h2>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <div className='w-[30%] hidden lg:block ml-3'>
        <RightSidebar />
      </div>
    </div>
  )
}
