import React from 'react'
import { Slick } from "@slick-slider/slick";
import sliderimage1 from '../../images/c1.jpg'
import sliderimage2 from '../../images/c3.jpg'
import sliderimage3 from '../../images/c4.jpg'
import { FaTv } from 'react-icons/fa6';
import { BiCricketBall } from 'react-icons/bi';
const images = [
    {
        url: sliderimage1,
    },
    {
        url: sliderimage2,
    },
    {
        url: sliderimage3,
    },
];

const data = [
    {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />,
        fa: "F"
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    }, {
        icon: <BiCricketBall />,
        Name: "Delhi Capitals",
        Name2: "Chennai Super King",
        Time: "Today 19.30",
        Tv: <FaTv />
    },
]

const RightSidebar = () => {
    return (
        <div className=' max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a] py-2'>
                <p className="bg-[#32383e] mx-2 py-2 text-white text-center px-2 mb-2 rounded my-auto">Live Casino Games</p>
                <div style={{ width: "100%", height: "100%", margin: "auto" }} className='px-2 '>
                    <Slick
                        keyExtractor={(image) => image.url}
                        urlExtractor={(image) => image.url}
                        onClick={(image) => {
                            console.log('image', image)
                        }}
                        images={images}
                        durationAnimation={500}
                        durationAutoPlay={3000}
                    />
                </div>
            </div>
            <div className='bg-[#22262a] py-2 mt-2'>
                <p className="bg-[#32383e] mx-2 py-2 text-sm text-gray-200 text-center px-2 mb-2 rounded my-auto">Popular Events</p>

                {data.map((val, index) => {
                    return (
                        <div className='flex mb-1 items-center mx-2 border-b-2 border-gray-700 py-2' >
                            <p className='bg-white text-black  rounded-full mr-2'>
                                <p className='text-2xl p-1'> {val.icon}</p>
                            </p>
                            <p className='flex flex-col text-xs text-gray-200'>
                                <span>{val.Name}</span>
                                <span>{val.Name2}</span>
                            </p>
                            <p className='ml-auto'>
                                <span className='flex justify-center text-xs text-gray-200'>{val.Time}</span>
                                <span className='flex justify-end mt-1'>
                                    <span className='mr-2 text-green-600'>  {val.Tv}</span>
                                    {val.fa ? (<span className='ml-2 bg-white text-black p-0 px-2 m-0 text-xs' >{val.fa}</span>) : ("")}

                                </span>
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RightSidebar