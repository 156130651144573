import React from 'react'
import { MainRouter } from '../../Router/MainRouter'
import { useLocation } from 'react-router-dom' // Import useLocation hook from react-router-dom
import HomeSidebar1 from '../Header/LeftSidebar'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Layout() {
  const location = useLocation(); // Get the current location

  // Check if the path contains 'casino'
  const isCasinoPath = location.pathname.includes('casino');

  return (
    <div>
      <Header />
      <div className='container mx-auto mt-2 '>
        <div className='flex gap-2 '>
          {/* Conditionally render HomeSidebar1 only if path doesn't contain 'casino' */}
          {!isCasinoPath && (
            <div className='w-[20%] hidden md:block  text-white'>
              <HomeSidebar1 />
            </div>
          )}
          <div className={`w-[100%] md:w-[${isCasinoPath ? '100' : '80'}%]  text-slate-50`}>
            <MainRouter />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
