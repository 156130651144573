import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';

const Currentbets = () => {
    const data = React.useMemo(
        () => [


            // Add more data as needed
        ],
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'BetId',
                accessor: 'BetId',
                width: 100, // Custom width for BetId column
            },
            {
                Header: 'Market Name',
                accessor: 'Market Name',
                width: 150, // Custom width for Market Name column
            },
            {
                Header: 'Runner Name',
                accessor: 'Runner Name',
                width: 150, // Custom width for Runner Name column
            },
            {
                Header: 'Side',
                accessor: 'Side',
                width: 80, // Custom width for Side column
            },
            {
                Header: 'Price',
                accessor: 'Price',
                width: 80, // Custom width for Price column
            },
            {
                Header: 'Matched Size',
                accessor: 'Matched Size',
                width: 120, // Custom width for Matched Size column
            },
            {
                Header: 'Remaining Size',
                accessor: 'Remaining Size',
                width: 120, // Custom width for Remaining Size column
            },
            {
                Header: 'Created Date',
                accessor: 'Created Date',
                width: 120, // Custom width for Created Date column
            },
            {
                Header: 'Updated Date',
                accessor: 'Updated Date',
                width: 120, // Custom width for Updated Date column
            },
            {
                Header: 'Action',
                accessor: 'Action',
                width: 100, // Custom width for Action column
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 }, // Initial page index and size
        },
        useSortBy,
        usePagination
    );

    return (
        <div className='max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a]  ml-2 md:ml-0 mr-0.5 md:mr-0     py-3.5 rounded px-5'>
                <p className='text-center text-lg font-bold mb-3'>MY BETS</p>
                <div className='flex items-center justify-between flex-col lg:flex-row  gradiant-bg-custom'>
                    <Link to={'/bets/currentbets'} className='flex rounded bg-[#169c59] w-full lg:w-1/4 border border-[#1d6844] justify-center   py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'> Current Bets</h6>
                    </Link>
                    <Link to={'/bets/betshistory'} className='flex rounded bg-transparent w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Bet History</h6>
                    </Link>
                    <Link to={'/bets/betprofitloss'} className='flex rounded bg-transparent w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Profit Loss</h6>
                    </Link>
                    <Link to={'/bets/casinoprofit-loss'} className='flex rounded bg-transparent w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Casino Profit Loss</h6>
                    </Link>

                </div>
                <div className='flex items-center md:flex-row flex-wrap my-4'>
                    <select name="" id="" className='bg-[#22262a] border border-zinc-500 text-gray-200 text-sm py-1 px-1.5'>
                        <option value="All">All</option>
                        <option value="Matched">Matched</option>
                        <option value="Unmatched">Unmatched</option>
                    </select>
                    <select name="" id="" className='ml-2 bg-[#22262a] border border-zinc-500 text-gray-200 text-sm py-1 px-1.5'>
                        <option value="All">All</option>
                        <option value="Back">Back</option>
                        <option value="Lay">Lay</option>
                    </select>
                </div>
                <p className='text-sm text-gray-200 my-2'>0 rows found</p>

                <div className='overflow-x-auto'>
                    <table {...getTableProps()} className='w-full mb-3'>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }} className='whitespace-nowrap text-gray-200 py-2 text-start  uppercase text-sm border-t border-b border-zinc-600 pr-2'>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {/* {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className='border-t border-gray-800'>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className='py-2 px-4 text-gray-200 text-sm'>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}


                                </tr>
                            );
                        })} */}
                        </tbody>
                    </table>
                    <p className='bg-[#d1ecf1]  text-[#0c5460] rounded text-xs p-3'>No records found for selected filter and time period.</p>
                </div>

                <p className='text-sm text-gray-200 my-2 mt-5'>0 Matched Bet Found</p>
                <div className='overflow-x-auto'>
                    <table {...getTableProps()} className='w-full mb-3'>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className='whitespace-nowrap text-gray-200 py-4 px-4 text-start uppercase text-sm border-t border-b border-zinc-600'
                                            style={{ width: column.width }} // Apply custom width here
                                        >

                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>

                        </tbody>
                    </table>
                </div>
                <p className='bg-[#d1ecf1] mb-4  text-[#0c5460] rounded text-xs p-3'>No records found for selected filter and time period.</p>
                {/* <div className='flex justify-end mt-4'>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className='text-sm  px-4 py-2 bg-[#169c59] text-white rounded mr-2'>
                        Previous
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className=' text-sm px-4 py-2 bg-[#169c59] text-white rounded'>
                        Next
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default Currentbets;
