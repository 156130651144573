// import React from 'react'
// import { FaMagnifyingGlass } from 'react-icons/fa6'

// const AccountStatement = () => {
//     return (
//         <div className=' max-h-screen overflow-y-scroll'>
//             <div className='bg-[#22262a] py-3.5 rounded px-5'>
//                 <p className='text-center text-lg font-bold'>ACCOUNT STATEMENT</p>
//                 <div className='flex items-center  flex-wrap my-4'>
//                     <input type="date" className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded ' />
//                     <p className='bg-[#169c59] flex items-center justify-center w-10 font-bold m-1.5 rounded h-9 text-center'>TO</p>
//                     <input type="date" className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded' />
//                     <p className='bg-[#169c59] flex items-center justify-center w-20 font-bold m-1.5 rounded h-9 text-center'><FaMagnifyingGlass className='mr-1.5' />Search</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default AccountStatement

import React, { useEffect, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { useTable, useSortBy, usePagination } from 'react-table';

const AccountStatement = () => {
    const data = React.useMemo(
        () => [
            {
                date: '2024-04-01',
                narration: 'narration 1',
                Pl: '$100',
                CreditLimit: '$100',
                Total: '$1000',
            },

            // Add more data as needed
        ],
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date Time',
                accessor: 'date',
            },
            {
                Header: 'Narration',
                accessor: 'narration',
            },
            {
                Header: 'Pl',
                accessor: 'Pl',
            },
            {
                Header: 'Credit Limit',
                accessor: 'CreditLimit',
            },
            {
                Header: 'Total',
                accessor: 'Total',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 }, // Initial page index and size
        },
        useSortBy,
        usePagination
    );
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        // Function to get current date in YYYY-MM-DD format
        const getCurrentDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1;
            let day = today.getDate();

            // Add leading zero if month or day is less than 10
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${year}-${month}-${day}`;
        };

        // Set current date
        setCurrentDate(getCurrentDate());
    }, []); // Runs only once on component mount

    return (
        <div className='max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a]  ml-2 md:ml-0 mr-0.5 md:mr-0     py-3.5 rounded px-5'>                <p className='text-center text-lg font-bold'>ACCOUNT STATEMENT</p>
                <div className='flex items-center  flex-wrap my-4'>
                    <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded' />
                    <p className='text-sm  bg-[#169c59] flex items-center justify-center w-10 font-bold m-1.5 rounded h-9 text-center'>TO</p>
                    <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded' />
                    <p className='text-sm  bg-[#169c59] flex items-center justify-center w-20 font-bold m-1.5 rounded h-9 text-center'><FaMagnifyingGlass className='mr-1.5' />Search</p>
                </div>
                <p className='text-sm text-gray-200 my-2'>0 rows found</p>
                <div className='overflow-x-auto mb-4'>
                    <table {...getTableProps()} className='min-w-full'>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className='whitespace-nowrap text-gray-200 py-2 text-start  uppercase text-sm border-t border-b border-zinc-600'>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className='border-t border-gray-800'>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()} className='py-2 px-4 text-gray-200 text-sm'>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <div className='flex justify-end mt-4'>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className='text-sm  px-4 py-2 bg-[#169c59] text-white rounded mr-2'>
                        Previous
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className=' text-sm px-4 py-2 bg-[#169c59] text-white rounded'>
                        Next
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default AccountStatement;
