import React, { useEffect, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';

const AccountStatement = () => {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        // Function to get current date in YYYY-MM-DD format
        const getCurrentDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1;
            let day = today.getDate();

            // Add leading zero if month or day is less than 10
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${year}-${month}-${day}`;
        };

        // Set current date
        setCurrentDate(getCurrentDate());
    }, []); // Runs only once on component mount

    return (
        <div className='max-h-screen overflow-y-scroll'>
            <div className='bg-[#22262a]  ml-2 md:ml-0 mr-0.5 md:mr-0     py-3.5 rounded px-5'>                <p className='text-center text-lg font-bold mb-3'>MY BETS</p>
                <div className='flex items-center justify-between flex-col lg:flex-row  gradiant-bg-custom'>
                    <Link to={'/bets/currentbets'} className='flex rounded  bg-transparent w-full lg:w-1/4 border border-[#1d6844] justify-center   py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'> Current Bets</h6>
                    </Link>
                    <Link to={'/bets/betshistory'} className='flex rounded bg-transparent  w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Bet History</h6>
                    </Link>
                    <Link to={'/bets/betprofitloss'} className='flex rounded bg-[#169c59] w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Profit Loss</h6>
                    </Link>
                    <Link to={'/bets/casinoprofit-loss'} className='flex rounded bg-transparent w-full lg:w-1/4 border border-[#1d6844] justify-center  py-1 cursor-pointer'>
                        <h6 className='font-bold text-gray-200'>Casino Profit Loss</h6>
                    </Link>

                </div>
                <div className='flex items-center md:flex-row flex-wrap my-4'>

                    <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded' />
                    <p className='text-sm  bg-[#169c59] flex items-center justify-center w-10 font-bold m-1.5 rounded h-9 text-center'>TO</p>
                    <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} className='bg-[#22262a] border text-gray-200 py-1 px-1 w-52 rounded' />
                    <p className='text-sm  bg-[#169c59] flex items-center justify-center w-20 font-bold m-1.5 rounded h-9 text-center'><FaMagnifyingGlass className='mr-1.5' />Search</p>

                </div>


                <div className='bg-[#d1ecf1] mb-4 w-full text-[#0c5460] rounded text-xs p-3'>No records found for selected filter and time period.</div>



            </div>
        </div>
    );
};

export default AccountStatement;
