

import React, { Navigate, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeLayout from '../Component/HomeMiddle/HomeLayout'
import Account from '../pages/Account'
import Event from '../pages/Event'
import AccountStatement from '../pages/AccountStatement'
import Currentbets from '../pages/Currentbets'
import Casinoprofitloss from '../pages/Casinoprofitloss'
import Betprofitloss from '../pages/Betprofitloss'
import Betshistory from '../pages/Betshistory'
import Bonusstatement from '../pages/Bonusstatement'
import DepositWithdraw from '../pages/Deposit'
import Crypto from '../pages/Crypto'
import Sports from '../pages/Sports'
import MyMarket from '../pages/MyMarket'
import InPlay from '../pages/InPlay'
import Casino from '../pages/Casino'
import Deposit from '../pages/Deposit'
import Withdraw from '../pages/Withdraw'


export const MainRouter = () => {

  return (
    <Routes>

      <Route path='/' element={<HomeLayout />} />
      <Route path='/event' element={<Event />} />
      <Route path='/account' element={<Account />} />
      <Route path='/account-statement' element={<AccountStatement />} />

      <Route path='/bets/currentbets' element={<Currentbets />} />
      <Route path='/bets/betshistory' element={<Betshistory />} />
      <Route path='/bets/betprofitloss' element={<Betprofitloss />} />
      <Route path='/bets/casinoprofit-loss' element={<Casinoprofitloss />} />
      <Route path='/bonus-statement' element={<Bonusstatement />} />
      <Route path='/deposit' element={<Deposit />} />
      <Route path='/withdraw' element={<Withdraw />} />
      <Route path='/my-market' element={<MyMarket />} />
      <Route path='/crypto' element={<Crypto />} />
      <Route path='/sports' element={<Sports />} />

      <Route path='/in-play' element={<InPlay />} />
      <Route path='/casino' element={<Casino />} />
      {/* <Route path='/dashboard' element={<Dashboard />} />  */}
      {/* <Route path='/event' element={<Event />} /> */}
      {/* <Route path='/chip-summary' element={<Chip_Summary />} /> */}
      {/* <Route path='/login' element={<Login />} />  */}
      {/* <Route path='/Sidebar' element={<Sidebar />} /> */}

    </Routes>
  )
}
