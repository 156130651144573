import logo from './logo.svg';
import './App.css';
import React, { Navigate, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom' 
import Layout from './Component/Layout/Layout';

function App() {
  return (
    <>
      
    <BrowserRouter>
    <Layout/></BrowserRouter>
   </>
  );
}

export default App;


















