import React, { useState } from 'react'
import { FaMobile } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6'

const Account = () => {
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);

  const handleUpdateMobileClick = () => {
    setShowMobilePopup(true);
  };

  const handleEditPasswordClick = () => {
    setShowPasswordPopup(true);
  };

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false);
  };

  const handleClosePasswordPopup = () => {
    setShowPasswordPopup(false);
  };
  return (
    <>
      <div className='container  mx-auto  pb-14 md:pb-0'>
        <div className='w-full p-5 bg-[#22262a]'>
          <p className='text-center'>My Profile</p>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 my-5 h-auto'>
            <div>
              <div className='bg-[#32383e] p-5 text-sm leading-7 h-auto'>
                <table className='table-auto w-full overflow-x-scroll'>
                  <tr>
                    <td>Name</td>
                    <td>comone01010805</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>9825032322</td>
                    <td onClick={handleUpdateMobileClick} className='cursor-pointer flex items-center justify-end'>Update Mobile <FaPencil className='ml-3' /></td>
                  </tr>
                  <tr>
                    <td>Password</td>
                    <td>*************</td>
                    <td onClick={handleEditPasswordClick} className='cursor-pointer flex items-center justify-end'>Edit <FaPencil className='ml-3' /></td>
                  </tr>
                  <tr>
                    <td>Languages</td>
                    <td >

                      <select name="" id="" className='text-black selector'>
                        <option value="english">English</option>
                        <option value="english">Hindi</option>
                        <option value="english">Gujarati</option>
                      </select>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Currency</td>
                    <td>FINR</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Time Zone</td>
                    <td>GMT+0530</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='bg-[#32383e] p-5 text-sm leading-7 h-auto'>
              <table className='table-auto w-full overflow-scroll relative'>

                <tr>
                  <th></th>
                  <th className='text-start'>MIN BET</th>
                  <th className='text-start'>MAX BET</th>
                  <th className='text-start'>BET DELAY</th>
                  <th className='text-start'>EXPOSURE</th>
                </tr>

                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
                <tr>
                  <td>Session</td>
                  <td>100</td>
                  <td>500,000</td>
                  <td>0</td>
                  <td>10,000,000</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        {showMobilePopup && (
          <div className="fixed inset-0 bg-[#000000b3] bg-opacity-50 flex justify-center items-center">
            <div className="bg-zinc-800 text-white rounded-lg p-6 w-[20rem] md:w-[25rem] lg:w-[30rem]">
              <div className=" mb-6 relative">
                <h2 className="text-lg text-center mx-auto font-bold">Change Your Mobile Number</h2>
                <button className="bg-[#169c59] rounded-full absolute top-0 right-0 text-black px-2 py-1 font-bold text-sm" onClick={handleCloseMobilePopup}>✕</button>
              </div>
              <form>
                <div className="mb-4">
                  <label className="block mb-1 text-sm">Current Number</label>
                  <div className="flex items-center bg-[#16191c] p-2 py-3 rounded">
                    <input type="text" value="98250" className=" flex-1 outline-none bg-[#16191c]" disabled />
                    <span><FaMobile /></span>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block mb-2 text-sm">New Number</label>
                  <div className="flex items-center bg-[#16191c] p-2 py-3 rounded">
                    <select className=" text-white outline-none bg-[#16191c]">
                      <option>(+91) India</option>
                    </select>
                    <input type="text" placeholder="New Mobile *" className=" flex-1 outline-none ml-2 bg-[#16191c]" />
                    <span><FaMobile /></span>
                  </div>
                </div>
                <div className="mb-4 flex items-center">
                  <input type="radio" id="otpWhatsapp" className="mr-2" />
                  <label htmlFor="otpWhatsapp">Receive OTP via Whatsapp</label>
                  <button type='button' className="ml-auto text-xs bg-[#22270c] text-white font-bold py-2 px-4 rounded">
                    Send OTP
                  </button>
                </div>
                <div className="mb-6">
                  <input type="text" placeholder="Enter OTP" className="py-3 w-full bg-zinc-700 p-2 rounded outline-none" />
                </div>
                <button type="button" className="w-full bg-[#169c59] text-white font-bold py-2 px-4 rounded">
                  CHANGE
                </button>
              </form>
            </div>
          </div>
        )}

        {showPasswordPopup && (
          <div className='fixed top-0 z-50 left-0 w-full mt-12 flex justify-center bg-[#000000b3] min-h-screen'>
            <div className=' text-white w-[20rem] md:w-[25rem] lg:w-[30rem]'>
              <div className='bg-black relative p-4  flex justify-between items-center pb-10'>
                <h2 className='text-xl  '>Change Password</h2>
                <button className='text-lg absolute top-4 right-4' onClick={handleClosePasswordPopup}>✕</button>
              </div>
              <div className='bg-[#0b0d0e]'>
                <form className=' leading-10 p-4'>
                  <div className='pb-3'>
                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter Current Password' />
                  </div>
                  <div className='pb-3'>
                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter New Password' />
                  </div>
                  <div className='pb-3'>
                    <input type="text" className='w-full border-b border-white bg-[#0b0d0e] text-sm focus:border-0 py-2 text-gray-200' placeholder='Please Enter Confirm Password' />
                  </div>
                  <div className='ml-auto mt-4 flex items-center flex-row justify-end'>
                    <button type='button' className='bg-[#169c59] hover:bg-[#0d8247] font-bold p-5 py-0 rounded'>Save</button>
                    <button type='button' className='bg-[#6c757d] hover:bg-[#5a6268] font-bold ml-4 p-5 py-0 rounded' onClick={handleClosePasswordPopup}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Account